import { render, staticRenderFns } from "./ModuleNftManager.vue?vue&type=template&id=440f4df1&scoped=true&"
import script from "./ModuleNftManager.vue?vue&type=script&lang=js&"
export * from "./ModuleNftManager.vue?vue&type=script&lang=js&"
import style1 from "./ModuleNftManager.vue?vue&type=style&index=1&id=440f4df1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "440f4df1",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/XDCWallet/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('440f4df1')) {
      api.createRecord('440f4df1', component.options)
    } else {
      api.reload('440f4df1', component.options)
    }
    module.hot.accept("./ModuleNftManager.vue?vue&type=template&id=440f4df1&scoped=true&", function () {
      api.rerender('440f4df1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/nft-manager/ModuleNftManager.vue"
export default component.exports