var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "module-nft-manager" },
    [
      _c("mew-module", {
        staticClass: "text-center d-flex justify-end flex-grow-1 pt-6 mr-3",
        attrs: {
          "has-elevation": true,
          "has-indicator": true,
          title: "NFT Manager",
        },
        scopedSlots: _vm._u([
          {
            key: "moduleBody",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "loader bgWalletBlockDark" },
                  [
                    _vm.loadingContracts && !_vm.hasNoTokens
                      ? _c("v-skeleton-loader", {
                          attrs: {
                            type: "table-heading,list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                (!_vm.loadingContracts &&
                  _vm.contracts.length === 0 &&
                  _vm.tabs.length === 0) ||
                _vm.hasNoTokens
                  ? _c(
                      "v-card",
                      {
                        staticClass: "d-flex align-center px-5 py-4",
                        attrs: {
                          flat: "",
                          color: "bgWalletBlockDark",
                          "min-height": "94px",
                        },
                      },
                      [
                        _c("v-card-text", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(_vm.$t("nftManager.none-owned")) + " "),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.loadingContracts &&
                !_vm.onNftSend &&
                _vm.tabs.length > 0 &&
                !_vm.hasNoTokens
                  ? _c(
                      "mew-tabs",
                      {
                        attrs: {
                          items: _vm.tabs,
                          "is-vertical": _vm.$vuetify.breakpoint.mdAndUp,
                          "has-underline": _vm.$vuetify.breakpoint.smAndDown,
                          "active-tab": _vm.activeTab,
                        },
                        on: { onTab: _vm.onTab },
                      },
                      [
                        _vm._l(_vm.contracts, function (contract, idx) {
                          return _c(
                            "template",
                            { slot: "tabItemContent" + (idx + 1) },
                            [
                              _c("div", { key: idx, staticClass: "ml-5" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-space-between mt-3 mb-5",
                                  },
                                  [
                                    _c(
                                      "h5",
                                      { staticClass: "font-weight-bold" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.selectedContract.name) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        "Total: " +
                                          _vm._s(_vm.selectedContract.total)
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm.displayedTokens &&
                                _vm.displayedTokens.length === 0
                                  ? _c("div", [_vm._v(" Loading ... ")])
                                  : _vm._e(),
                                _vm.displayedTokens &&
                                _vm.displayedTokens.length !== 0
                                  ? _c(
                                      "div",
                                      [
                                        _vm._l(
                                          _vm.displayedTokens,
                                          function (token, tokenIdx) {
                                            return _c(
                                              "div",
                                              {
                                                key: tokenIdx,
                                                staticClass: "mb-3",
                                              },
                                              [
                                                _c("nft-manager-details", {
                                                  attrs: {
                                                    loading: _vm.loadingTokens,
                                                    "on-click": _vm.openNftSend,
                                                    token: token,
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        _vm.hasPages
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "px-4 mt-3 d-flex align-center justify-end",
                                              },
                                              [
                                                _c("v-pagination", {
                                                  staticClass: "nft-pagination",
                                                  attrs: {
                                                    color: "expandHeader",
                                                    length: _vm.totalPages,
                                                    "prev-icon":
                                                      "mdi-menu-left",
                                                    "next-icon":
                                                      "mdi-menu-right",
                                                  },
                                                  on: { input: _vm.setPage },
                                                  model: {
                                                    value: _vm.currentPage,
                                                    callback: function ($$v) {
                                                      _vm.currentPage = $$v
                                                    },
                                                    expression: "currentPage",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          )
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.onNftSend
                  ? _c("nft-manager-send", {
                      attrs: {
                        close: _vm.closeNftSend,
                        nft: _vm.selectedNft,
                        "nft-category": _vm.selectedContract.name,
                        send: _vm.sendTx,
                        disabled: !_vm.isValid,
                        "enough-funds": _vm.enoughFunds,
                        "show-balance-error": _vm.showBalanceError,
                        "set-address": _vm.setAddress,
                      },
                      on: { hasMinEth: _vm.hasMinEth },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }